admin.payout = {

    edit: function()
    {
        $('body').on('click', '.update-payout', function () {
            var $this = $(this),
                url = $this.attr('href');

            $.ajax(
                {
                    url: url,
                    type: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        $('#update-payout-wrapper').html(data.modal);
                        admin.base.openModal('#update-payout-info');
                    },
                    error: function (xhr, evt, status) {
                    }
                });
            return false;
        });
    },

    update: function()
    {
        $('body').on('click', '.update-payout-trigger', function () {
            var $this = $(this),
                url = $this.attr('href');

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        $('.payout_' + data.payout_id + ' .payout_status').text('Completed');
                        $('.payout_' + data.payout_id + ' .payout_submitted_at').text(data.payout_submitted_at);
                        $('#update-payout-info').modal('hide');
                    },
                    error: function (xhr, evt, status) {
                    }
                });
            return false;
        });
    }
}