admin.base = {
    jsonErrors: function (xhr, evt, status, form) {
        var content, value, _i, _len, _ref, $this, contentWrapper;
        $this = form;
        contentWrapper = $this.find('.json-error-wrapper');
        content = contentWrapper.find('#errors');
        content.find('ul').empty();
        _ref = $.parseJSON(xhr.responseText).errors;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            value = _ref[_i];
            content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
        }
        contentWrapper.show();
    },

    mdbootstrap: function () {
        $('.mdb-select').materialSelect();
        $(".button-collapse").sideNav();
        $('.field_with_errors + label').addClass('active');
        $('.mdb-select').click(e => e.stopPropagation());

        $('.table-responsive').on('shown.bs.dropdown', function (e) {
            var t = $(this),
                m = $(e.target).find('.dropdown-menu'),
                tb = t.offset().top + t.height(),
                mb = m.offset().top + m.outerHeight(true),
                d = 20; // Space for shadow + scrollbar.
            if (t[0].scrollWidth > t.innerWidth()) {
                if (mb + d > tb) {
                    t.css('padding-bottom', ((mb + d) - tb));
                }
            } else {
                t.css('overflow', 'visible');
            }
        }).on('hidden.bs.dropdown', function () {
            $(this).css({
                'padding-bottom': '',
                'overflow': ''
            });
        });

        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
            $('.dropdown-toggle').dropdown();
        })
    },

    openModal: function (target) {
        $(target).modal(
            {
                backdrop: 'static',
                keyboard: false
            });
    },

    string_parameterize: function (string) {
        return string.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
    },

    getUrlVars: function () {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    },

    addFlash: function (alertClass, message) {
        $('#admin-wrapper').prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
    },

    scrollTop: function (element) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(element).offset().top
        }, 1000);
    },

    addCustomFlash: function (element, alertClass, message) {
        $(element).prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
    },

    datePicker: function () {
        $('.datepicker').pickadate({
            clear: 'Clear selection',
            close: 'Cancel',
            format: 'dd/mm/yyyy',
            formatSubmit: 'yyyy-mm-dd',
            hiddenName: true,
        })
    },

    initCharCounter: function (formElem, counterElem, count) {
        $(formElem).keyup(function () {
            $(counterElem).html("<small><i>Characters left: " + (count - $(this).val().length) + "</i></small>");
        });
    },

    updateQueryStringParameter: function (uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            return uri + separator + key + "=" + value;
        }
    },

    changeSearchLimitParam: function () {
        $('#limit_param').on('change', function () {
            window.location = admin.base.updateQueryStringParameter(window.location.href, 'per_page', this.value);
            $('#search_limit').val(this.value);
        });
    },

    newColorPicker: function (targetElem, defaultColor, inputField) {
        new Pickr({
            el: targetElem,
            default: defaultColor,
            components: {
                preview: true,
                opacity: true,
                hue: true,

                interaction: {
                    hex: true,
                    rgba: true,
                    input: true,
                    clear: true,
                    save: true
                }
            },
            onChange(hsva, instance) {
                $(inputField).val(hsva.toRGBA().toString());
            }
        });
    }
}