admin.customer = {

    createCsvReport: function () {
        $('#export-customer-csv-button').on('click', function () {
            $('.csv-report-flash').remove();

            $.ajax(
                {
                    url: '/customer_csvs',
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        $('#admin-flashes').prepend('<div class="alert alert-success csv-report-flash" role="alert">Successfully created the CSV report. Please check your current email account for a link to the file.</div>')
                        admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        $('#admin-flashes').prepend('<div class="alert alert-warning csv-report-flash" role="alert">Unable to create the CSV report. Please make sure you have customers with marketing optin enabled.</div>');
                        admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },
}