$(document).ready(function () {
    admin.base.mdbootstrap();
    admin.base.changeSearchLimitParam();

    admin.payout.edit();
    admin.payout.update();

    admin.customer.createCsvReport();
});

$(document).ajaxComplete(function() {
    $("input[type=text]").trigger("focusin");
    $("input[type=text]").trigger("blur");
});