window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("jquery-ui-dist/jquery-ui");

require("moment-timezone");

require("mdbootstrap/bootstrap");
require("mdbootstrap/mdb");
require("mdbootstrap/modules/dropdown");

require("admin/_admin");
require("admin/admin.base");
require("admin/admin.charity");
require("admin/admin.payout");
require("admin/admin.customer");
require("admin/admin.ready");